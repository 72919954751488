<template>
  <sidebar-comp
    :user="user"
    :title="routeName"
    :menuItems="showMenu.showMenu"
  ></sidebar-comp>
  <v-main>
    <slot />
    <footer-comp></footer-comp>
  </v-main>
</template>
<script>
import SidebarComp from "./SidebarComp.vue";
import FooterComp from "./FooterComp.vue";
export default {
  components: {
    SidebarComp,
    FooterComp,
  },
  computed: {},
  async created() {
    this.menuItems.menuItems = [
      {
        title: "Yönetim Paneli",
        icon: "mdi-application-cog-outline",
        route: "",
        child: [
          {
            title: "Rol Listeleme",
            icon: "mdi-badge-account",
            route: "/#/roles",
          },
          {
            title: "Kullanıcı Listeleme",
            icon: "mdi-account-details",
            route: "/#/users",
          },
          {
            title: "Yetki Listeleme",
            icon: "mdi-account-lock-outline",
            route: "/#/permissions",
          },
        ],
      },
      {
        title: "Kredi Yönetim Paneli",
        icon: "mdi-file-cog-outline",
        route: "",
        child: [
          {
            title: "Kredi Ekleme",
            icon: "mdi-credit-card-plus-outline",
            route: "/#/credit/add",
          },
          {
            title: "Bayi Kredileri Listeleme",
            icon: "mdi-credit-card-settings-outline",
            route: "/#/dealers_credits",
          },
          {
            title: "Kredi Hareketleri Listeleme",
            icon: "mdi-credit-card-settings-outline",
            route: "/#/credit_histories",
          },
        ],
      },
      {
        title: "Bayi Yönetim Paneli",
        icon: "mdi-store-cog-outline",
        route: "",
        child: [
          {
            title: "Bayi Listeleme",
            icon: "mdi-store-marker-outline",
            route: "/#/dealers",
          },
          {
            title: "Slip No Kaydı Listeleme (Bayi)",
            icon: "mdi-store-marker-outline",
            route: "/#/dealer_slips",
          },
        ],
      },
      {
        title: "Bayi Dashboard",
        icon: "mdi-store-search-outline",
        route: "/#/dealers/dashboard",
      },
      {
        title: "Araç Listeleme (Bayi)",
        icon: "mdi-taxi",
        route: "/#/dealers_vehicle_list",
      },
      {
        title: "Araç Listeleme",
        icon: "mdi-taxi",
        route: "/#/vehicles",
      },
      {
        title: "İşlem Kayıt Ekranı (Bayi)",
        icon: "mdi-taxi",
        route: "/#/create_process_with_slip_by_dealer",
      },
      {
        title: "İşlem Kayıt Ekranı (Zam)",
        icon: "mdi-taxi",
        route: "/#/create_process_with_slip",
      },
      {
        title: "İşlem Kayıt Ekranı",
        icon: "mdi-taxi",
        route: "/#/create_process",
      },
      {
        title: "Slip Doğrulama Ekranı",
        icon: "mdi-file-question-outline",
        route: "/#/slip_control",
      },
      {
        title: "Mühür Belgesi Sorgu Ekranı",
        icon: "mdi-file-question-outline",
        route: "/#/muhur_sorgu",
      },
      {
        title: "Raporlama Paneli",
        icon: "mdi-application-cog",
        route: "",
        child: [
          {
            title: "Yapılan İşlem Listeleme",
            icon: "mdi-badge-account",
            route: "/#/reports/process_log_list",
          },
          {
            title: "Slip Listeleme",
            icon: "mdi-badge-account",
            route: "/#/reports/slips",
          },
        ],
      },
      {
        title: "Ayarlar",
        icon: "mdi-cog",
        route: "",
        child: [
          {
            title: "Panel Ayarları",
            icon: "mdi-cog",
            route: "/#/options",
          },
          {
            title: "Tarife Ayarları",
            icon: "mdi-cog",
            route: "/#/tariff_settings",
          },
          {
            title: "Şehir Ayarları",
            icon: "mdi-cog",
            route: "/#/city_settings",
          },
        ],
      },
    ];
    this.showMenu.showMenu = this.menuItems.menuItems;
  },
  methods: {},
  data() {
    return {
      routeName: this.$route.name,
      user: {
        fullName: null,
        roleName: null,
        dealerName: null,
        dealerCredit: null,
        showBalance: false,
      },
      menuItems: {
        menuItems: [],
      },
      showMenu: {
        showMenu: [],
      },
    };
  },
};
</script>
<style>
body {
  font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}
.v-main {
  background-color: #e9ecef;
  min-height: 87vh !important;
}
.v-container {
  min-height: 87vh;
}
</style>
