import { createRouter, createWebHashHistory } from "vue-router";
const routes = [
  {
    name: "HomePage",
    path: "/",
    component: () => import("@/pages/HomePage"),
  },
  {
    name: "AboutPage",
    path: "/about",
    component: () => import("@/pages/AboutPage"),
  },
  {
    name: "ProductCategoryPage",
    path: "/product-category/:category",
    component: () => import("@/pages/ProductCategoryPage"),
  },
  {
    name: "ReferencePage",
    path: "/references",
    component: () => import("@/pages/ReferencePage"),
  },
  {
    name: "ContactPage",
    path: "/contact",
    component: () => import("@/pages/ContactPage"),
  },
  {
    name: "ErrorPage",
    path: "/forbidden",
    component: () => import("@/pages/ErrorPage.vue"),
  },
  {
    name: "404Page",
    path: "/:catchAll(.*)", // Herhangi bir yol ile eşleşecek
    component: () => import("@/pages/404Page"), // 404 sayfası
  },
];
const router = createRouter({
  routes,
  history: createWebHashHistory(),
});
export default router;
