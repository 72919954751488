import { defineStore } from "pinia";
import {
  getCategories,
  getSetting,
  getReferences,
  getSliders,
  getAbouts,
  getCategoryInfo,
  getProducts,
  listServiceAreas,
} from "../utils/GeneralService";
export const useGeneralStore = defineStore("generalStore", {
  state: () => ({
    language: "tr",
  }),
  getters: {
    getLanguage: (state) => state.language,
  },
  actions: {
    async getCategoriesAction() {
      const response = await getCategories(this.language);
      return response;
    },
    async getSettingAction() {
      const response = await getSetting();
      return response;
    },
    async getReferencesAction() {
      const response = await getReferences();
      return response;
    },
    async getSlidersAction() {
      const response = await getSliders(this.language);
      return response;
    },
    async getAboutsAction() {
      const response = await getAbouts(this.language);
      return response;
    },
    async getCategoryInfoAction(slug) {
      const response = await getCategoryInfo(this.language, slug);
      return response;
    },
    async getProductsAction(categoryId) {
      const response = await getProducts(categoryId);
      return response;
    },
    async listServiceAreasAction() {
      const response = await listServiceAreas();
      return response;
    },
  },
  persist: {
    enabled: true,
    strategies: [
      {
        storage: localStorage,
      },
    ],
  },
});
