import { nodeCmsUrl } from "./appAxios";
const websiteId = "66965980d7d789e33281dfd5";
export const getSetting = async () => {
  try {
    const { data, status, statusText } = await nodeCmsUrl.get(
      `/settings/front/${websiteId}`
    );
    if (!data)
      return {
        status: false,
        statusCode: status,
        message: statusText,
        data: null,
      };
    return { status: true, statusCode: status, message: statusText, data };
  } catch (error) {
    return {
      status: false,
      statusCode: error?.response?.status || 500,
      message: error?.response?.data?.error?.message || error?.message,
      data: null,
    };
  }
};
export const getReferences = async () => {
  try {
    const { data, status, statusText } = await nodeCmsUrl.get(
      `/references/front/${websiteId}`
    );
    if (!data)
      return {
        status: false,
        statusCode: status,
        message: statusText,
        data: null,
      };
    return { status: true, statusCode: status, message: statusText, data };
  } catch (error) {
    return {
      status: false,
      statusCode: error?.response?.status || 500,
      message: error?.response?.data?.error?.message || error?.message,
      data: null,
    };
  }
};
export const getSliders = async (language) => {
  try {
    const { data, status, statusText } = await nodeCmsUrl.get(
      `/sliders/front/${websiteId}/${language}`
    );
    if (!data)
      return {
        status: false,
        statusCode: status,
        message: statusText,
        data: null,
      };
    return { status: true, statusCode: status, message: statusText, data };
  } catch (error) {
    return {
      status: false,
      statusCode: error?.response?.status || 500,
      message: error?.response?.data?.error?.message || error?.message,
      data: null,
    };
  }
};
export const getCategories = async (language) => {
  try {
    const { data, status, statusText } = await nodeCmsUrl.get(
      `/categories/front/${websiteId}/${language}`
    );
    if (!data)
      return {
        status: false,
        statusCode: status,
        message: statusText,
        data: null,
      };
    return { status: true, statusCode: status, message: statusText, data };
  } catch (error) {
    return {
      status: false,
      statusCode: error?.response?.status || 500,
      message: error?.response?.data?.error?.message || error?.message,
      data: null,
    };
  }
};
export const getCategoryInfo = async (language, slug) => {
  try {
    const { data, status, statusText } = await nodeCmsUrl.get(
      `/categories/front/${websiteId}/${language}/${slug}`
    );
    if (!data)
      return {
        status: false,
        statusCode: status,
        message: statusText,
        data: null,
      };
    return { status: true, statusCode: status, message: statusText, data };
  } catch (error) {
    return {
      status: false,
      statusCode: error?.response?.status || 500,
      message: error?.response?.data?.error?.message || error?.message,
      data: null,
    };
  }
};
export const getAbouts = async (language) => {
  try {
    const { data, status, statusText } = await nodeCmsUrl.get(
      `/abouts/front/${websiteId}/${language}`
    );
    if (!data)
      return {
        status: false,
        statusCode: status,
        message: statusText,
        data: null,
      };
    return { status: true, statusCode: status, message: statusText, data };
  } catch (error) {
    return {
      status: false,
      statusCode: error?.response?.status || 500,
      message: error?.response?.data?.error?.message || error?.message,
      data: null,
    };
  }
};
export const getProducts = async (categoryId) => {
  try {
    const { data, status, statusText } = await nodeCmsUrl.get(
      `/products/front/${categoryId}`
    );
    if (!data)
      return {
        status: false,
        statusCode: status,
        message: statusText,
        data: null,
      };
    return { status: true, statusCode: status, message: statusText, data };
  } catch (error) {
    return {
      status: false,
      statusCode: error?.response?.status || 500,
      message: error?.response?.data?.error?.message || error?.message,
      data: null,
    };
  }
};
export const listServiceAreas = async () => {
  try {
    const { data, status, statusText } = await nodeCmsUrl.get(
      `/service_areas/front/${websiteId}`
    );
    if (!data)
      return {
        status: false,
        statusCode: status,
        message: statusText,
        data: null,
      };
    return { status: true, statusCode: status, message: statusText, data };
  } catch (error) {
    return {
      status: false,
      statusCode: error?.response?.status || 500,
      message: error?.response?.data?.error?.message || error?.message,
      data: null,
    };
  }
};
