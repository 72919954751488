<template>
  <VFooter style="background-color: #212529">
    <VRow class="container py-6">
      <VCol sm="12" md="12" lg="4" xl="4">
        <span
          class="font-weight-bold text-capitalize text-h6 text-white mb-4"
          >{{ title }}</span
        >
        <VDivider class="my-4 text-white"></VDivider>
        <span class="footer-color">
          {{ description }}
        </span>
      </VCol>
      <VCol sm="12" md="6" lg="2" xl="2">
        <div class="pa-2">
          <a
            class="font-weight-medium text-capitalize text-subtitle-1 bhover justify-start"
            :href="`/#/about`"
          >
            Şirketimiz
          </a>
        </div>
        <div class="pa-2">
          <a
            class="font-weight-medium text-capitalize text-subtitle-1 bhover justify-start"
            :href="`/#/references`"
          >
            İş Ortakları
          </a>
        </div>
        <div class="pa-2">
          <a
            class="font-weight-medium text-capitalize text-subtitle-1 bhover justify-start"
            :href="`/#/contact`"
          >
            İletişim
          </a>
        </div>
      </VCol>
      <VCol sm="12" md="6" lg="2" xl="2">
        <div
          v-for="category in categories.categories"
          :key="category.id"
          class="pa-2"
        >
          <a
            class="font-weight-medium text-capitalize text-subtitle-1 bhover justify-start"
            :href="`/#/product-category/${category?.slug}`"
            >{{ category?.name }}</a
          >
        </div>
      </VCol>
      <VCol sm="12" md="6" lg="4" xl="4">
        <span class="font-weight-bold text-h6 text-white mb-6">İLETİŞİM</span>
        <VDivider class="my-4 text-white"></VDivider>
        <span class="footer-color">
          <VIcon icon="mdi-map-marker" class="mr-2"></VIcon>{{ address }}
        </span>
        <VDivider class="my-2"></VDivider>
        <span class="footer-color">
          <VIcon icon="mdi-phone" class="mr-2"></VIcon>
          <a class="ahover" :href="`tel:${phone}`">{{ phone }}</a>
        </span>
        <VDivider class="my-2"></VDivider>
        <span class="footer-color">
          <VIcon icon="mdi-email-outline" class="mr-2"></VIcon>
          <a class="ahover" :href="`mailto:${email}`">{{ email }}</a>
        </span>
      </VCol>
    </VRow>
  </VFooter>
  <v-footer style="background-color: #1c2023" elevation="15">
    <v-row class="container" justify="space-between" align="center">
      <v-col cols="auto" align-self="start">
        <span style="font-weight: 500; color: #f0f0f0">
          Copyright &copy; 2024. {{ title }} Tüm Hakları saklıdır.
        </span>
      </v-col>
      <v-col cols="auto" align-self="center"> </v-col>
      <v-col cols="auto" align-self="end">
        <span style="font-weight: 500; color: #f0f0f0"
          >{{ title }} adına
          <a
            href="http://www.mobilulasim.com/"
            class="ahover"
            rel="noopener"
            target="_blank"
          >
            İpos Mobil Ulaşım
          </a>
          tarafından geliştirilmiştir.
        </span>
      </v-col>
    </v-row>
  </v-footer>
</template>
<script>
import footerLogo from "../../../public/assets/img/tetas_footer.png";
import { mapActions } from "pinia";
import { useGeneralStore } from "@/stores/GeneralStore";
export default {
  data() {
    return {
      footerLogo,
      address: null,
      phone: null,
      email: null,
      description: null,
      title: null,
      categories: {
        categories: [],
      },
    };
  },
  methods: {
    ...mapActions(useGeneralStore, ["getSettingAction", "getCategoriesAction"]),
  },
  async created() {
    const responseSetting = await this.getSettingAction();
    if (responseSetting?.status) {
      this.address = responseSetting?.data?.address;
      this.phone = responseSetting?.data?.phone;
      this.email = responseSetting?.data?.email;
      this.description = responseSetting?.data?.description;
      this.title = responseSetting?.data?.title;
    } else {
      this.address = null;
      this.phone = null;
      this.email = null;
      this.description = null;
      this.title = null;
    }
    const responseCategories = await this.getCategoriesAction();
    if (responseCategories) {
      this.categories.categories = responseCategories?.data;
    } else {
      this.categories.categories = [];
    }
  },
};
</script>
<style scoped>
.v-row {
  height: 100%;
}
.v-col {
  height: 100%;
}
</style>
