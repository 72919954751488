<template>
  <v-app>
    <router-view></router-view>
  </v-app>
</template>
<script>
export default {};
</script>
<style>
body {
  font-family: "Poppins", "Source Sans Pro", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol" !important;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}
.text-h6,
.text-h5,
.text-h4,
.text-h3,
.text-h2,
.text-h1,
p,
span,
.v-btn,
.text-subtitle-1,
.subtitle,
.title,
.text-body-1,
.text-body-2,
.text-button,
.text-caption,
.text-overline,
.text-capitalize,
.text-subtitle-2 {
  font-family: "Poppins", "Source Sans Pro", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol" !important;
}
</style>
