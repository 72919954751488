import { createApp } from "vue";
import { getSetting } from "@/utils/GeneralService";
import router from "./router";
// import { ApmVuePlugin } from '@elastic/apm-rum-vue'
import App from "./App.vue";
import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import * as labsComponents from "vuetify/labs/components";
import colors from "vuetify/lib/util/colors";
import "@mdi/font/css/materialdesignicons.css";
import "sweetalert2/src/sweetalert2.scss";
import "../public/assets/style/main.css";
import BaseLayoutComp from "@/components/MainComponents/BaseLayoutComp";
const vuetify = createVuetify({
  components: {
    ...components,
    ...labsComponents,
  },
  directives,
  theme: {
    themes: {
      light: {
        dark: false,
        colors: {
          greyLogin: colors.grey.lighten5,
        },
      },
    },
  },
});
import { createPinia } from "pinia";
import piniaPersist from "pinia-plugin-persist";
const pinia = createPinia();
pinia.use(piniaPersist);
const app = createApp(App);
app.use(router);
app.use(vuetify);
app.use(pinia);
app.component("BaseLayoutComp", BaseLayoutComp);
getSetting().then((response) => {
  if (response?.status) {
    const siteInfo = response?.data;
    // Başlığı güncelle
    document.title = siteInfo.title;
    // Favicon'u güncelle
    let link =
      document.querySelector("link[rel*='icon']") ||
      document.createElement("link");
    link.type = "image/x-icon";
    link.rel = "shortcut icon";
    link.href = siteInfo.favicon;
    document.getElementsByTagName("head")[0].appendChild(link);
  } else {
    console.error("API isteğinde hata oluştu:", response?.message);
  }
});
app.mount("#app");
