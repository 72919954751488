<template>
  <v-navigation-drawer v-model="drawer" app temporary v-if="isMobile">
    <a href="/#/">
      <v-img
        v-if="logoSrc"
        :src="logoSrc"
        style="height: 10vmin; margin: 10% auto"
      ></v-img>
    </a>
    <VBtn
      variant="plain"
      block
      size="large"
      class="font-weight-medium text-capitalize text-h6"
      href="/#/about"
    >
      Şirketimiz
    </VBtn>
    <v-btn
      variant="plain"
      block
      size="large"
      class="font-weight-medium text-capitalize text-h6"
      append-icon="$dropdown"
    >
      Ürünler
      <v-menu activator="parent">
        <v-list>
          <v-list-item>
            <v-list-item-title
              v-for="category in categories.categories"
              :key="category"
            >
              <VBtn
                :href="`/#/product-category/${category?.slug}`"
                size="large"
                class="font-weight-medium text-capitalize text-h6"
                block
                variant="plain"
              >
                {{ category?.name }}
              </VBtn>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-btn>
    <VBtn
      href="/#/references"
      variant="plain"
      block
      size="large"
      class="font-weight-medium text-capitalize text-h6"
    >
      İş Ortaklarımız
    </VBtn>
    <VBtn
      href="/#/contact"
      variant="plain"
      block
      size="large"
      class="font-weight-medium text-capitalize text-h6"
    >
      İletişim
    </VBtn>
  </v-navigation-drawer>
  <VAppBar v-if="isMobile" app>
    <v-app-bar-nav-icon
      @click="toggleDrawer"
      v-if="isMobile"
    ></v-app-bar-nav-icon>
  </VAppBar>
  <VAppBar height="120" v-if="!isMobile" app>
    <VSpacer></VSpacer>
    <VAppBarTitle>
      <a href="/">
        <v-img
          v-if="logoSrc"
          :src="logoSrc"
          style="height: 5vmin; margin: auto"
        ></v-img>
      </a>
    </VAppBarTitle>
    <VSpacer></VSpacer>
    <VRow>
      <VCol>
        <div>
          <VBtn
            href="/#/about"
            size="large"
            class="font-weight-medium text-capitalize text-subtitle-1"
          >
            Şirketimiz
          </VBtn>
          <v-btn
            size="large"
            class="font-weight-medium text-capitalize text-subtitle-1"
            append-icon="$dropdown"
          >
            Ürünler
            <v-menu activator="parent">
              <v-list>
                <v-list-item
                  v-for="category in categories.categories"
                  :key="category"
                >
                  <v-list-item-title>
                    <VBtn
                      @click="navigate(`/product-category/${category?.slug}`)"
                      size="large"
                      class="font-weight-medium text-capitalize text-subtitle-1"
                      block
                      variant="plain"
                    >
                      {{ category?.name }}
                    </VBtn>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-btn>
          <VBtn
            href="/#/references"
            size="large"
            class="font-weight-medium text-capitalize text-subtitle-1"
          >
            İş Ortaklarımız
          </VBtn>
          <VBtn
            href="/#/contact"
            size="large"
            class="font-weight-medium text-capitalize text-subtitle-1"
          >
            İletişim
          </VBtn>
        </div>
      </VCol>
    </VRow>
  </VAppBar>
</template>
<script>
import { mergeProps } from "vue";
import logoSideBar from "../../../public/assets/img/quatro_black_logo.svg";
import {} from "@/utils/GeneralService";
import { useGeneralStore } from "@/stores/GeneralStore";
import { mapActions } from "pinia";
export default {
  props: ["menuItems", "title", "user"],
  data() {
    return {
      rail: false,
      logoSrc: logoSideBar,
      drawer: false, // Çekmeceyi kontrol etmek için
      isMobile: false, // Mobil cihaz kontrolü için
      categories: {
        categories: [],
      },
    };
  },
  unmounted() {
    window.removeEventListener("resize", this.handleResize);
  },
  async created() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
    const responseCategories = await this.getCategoriesAction();
    if (responseCategories?.status) {
      this.categories.categories = responseCategories.data;
    } else {
      this.categories.categories = [];
    }
    const responseSetting = await this.getSettingAction();
    if (responseSetting?.status) {
      this.logoSrc = responseSetting?.data?.logo;
    } else {
      this.logoSrc = null;
    }
  },
  methods: {
    ...mapActions(useGeneralStore, ["getCategoriesAction", "getSettingAction"]),
    async navigate(route) {
      await this.$router.replace(route);
      this.$router.go();
    },
    drawClick() {
      this.drawer = !this.drawer;
    },
    mergeProps,
    handleResize() {
      this.isMobile = window.innerWidth < 960; // 960px altı ekran genişliği mobil olarak kabul edilir
    },
    toggleDrawer() {
      this.drawer = !this.drawer;
    },
    async logOutFunc() {
      const response = await this.logout();
      if (response.status) {
        this.navigate("/login");
      }
    },
  },
};
</script>
<style scoped>
.v-app-bar {
  min-height: 7vh !important; /* Daha önce 10vh olarak belirtilmişti, 7vh olarak güncellendi */
}
.v-btn {
  margin: 2px 0 !important; /* Düğmeler arasında boşluk azaltma */
}
.v-list-group__items .v-list-item {
  -webkit-padding-start: calc(10%) !important;
  padding-inline-start: calc(10%) !important;
}
.pointer {
  cursor: pointer;
}
</style>
